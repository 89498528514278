export const state = () => ({
  token: "",
  // email: "",
  userInfo: {},
  dialogVisible: false,
  mobileLoginDialogVisible: false,
  loginSuccess: false,
  compareList:[],
  jumpList:'contact'
});
export const mutations = {
  setToken(state, token) {
    state.token = token;
    this.$cookies.set("token", token, { maxAge: 2592000, path: '/' });
  },
  getToken(state) {
    state.token = this.$cookies.get("token");
  },
  setUserInfo(state, data) {
    state.userInfo = data;
    // state.email = data.email;
    this.$cookies.set("user-info", data,  { maxAge: 2592000, path: '/' });
  },
  clearUserInfo(state) {
    state.userInfo = {};
    // state.email = "";
    state.token = "";
    this.$cookies.remove("token");
    this.$cookies.remove("user-info");
  },
  openDialog(state) {
    state.dialogVisible = true;
  },
  closeDialog(state) {
    state.dialogVisible = false;
  },
  setLoginSuccess(state, status) {
    state.loginSuccess = status;
  },
  openMobileLoginDialog(state) {
    state.mobileLoginDialogVisible = true
  },
  closeMobileLoginDialog(state) {
    state.mobileLoginDialogVisible = false
  },
  setCompareList(state,data) {
    state.compareList = data;
  },
  clearCompareList(state) {
    state.compareList = [];
  },
};
export const actions = {
  login({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      if (data) {
        this.$axios({
          url: "/au/login",
          method: "POST",
          data,
        }).then((res) => {
          commit("setToken", res.result.token);
          dispatch("getUserInfo");
          commit("setLoginSuccess", true); // 登录成功后更新状态
          resolve(res);
        });
      }
    }).catch(() => {
      // reject(error);
    });
  },
  getUserInfo({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      this.$axios({
        url: "au/member/detail",
        method: "GET",
      }).then((res) => {
        sessionStorage.setItem('memberCrmcomList',JSON.stringify(res.result.memberCrmcomList))
        if (res.result?.memberCrmcom) delete res.result.memberCrmcom;
        if (res.result?.memberCrmcomList) delete res.result.memberCrmcomList;
        commit("setUserInfo", res.result);
        resolve(res);
        
        if(location.pathname.indexOf('contact')>-1){
          location.href = location.origin
        }
      });
    }).catch(() => {
      // reject(error);
    });
  },
  getCompareList({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post("home/compare_list", { compare_keys: "keys" })
        .then((res) => {
          commit("setCompareList", res.result);
          resolve(res);
        });
    }).catch(() => {
      // reject(error);
    });
  },
  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      //未有退出登录接口
      commit("clearUserInfo");
      resolve();
    }).catch(() => {
      // reject(error);
    });
  },
};
