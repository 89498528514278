export default ({ app }) => {
  // 在客户端（浏览器）中执行
  if (process.client) {
    // 只在生产环境加载 Google Analytics 脚本
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-MZQ91YR2W2';
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-MZQ91YR2W2');
  }
};
