
import { mapMutations } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import algoliasearch from 'algoliasearch'

export default {
  data() {
    return {
      // loaded: false,
      loading: false,
      timer: '',
      keyword: '',
      result: { cate: [], subCate: [], firstCate: [] },
      showType: 'none',
      matchingList: [],
      categoryList: [],
      leadTime: [],
      budget: [],
      compareList: [],
      indentIdeasArticle: {},
      complianceArticle: {},
      isShopBlock: false,
      isLeadTimeBlock: false,
      isBudgetBlock: false,
      qtyList: [
        {
          value: 50,
          name: 50,
        },
        {
          value: 100,
          name: 100,
        },
        {
          value: 250,
          name: 250,
        },
        {
          value: 500,
          name: 500,
        },
        {
          value: 1000,
          name: '1,000',
        },
        {
          value: 2500,
          name: '2,500',
        },
        {
          value: 5000,
          name: '5,000',
        },
        {
          value: 10000,
          name: '10,000',
        },
      ],
      index: null,
    }
  },
  async fetch() {
    const p1 = this.$axios.post('/common/shopProductList').then(res => {
      if (res.code === 1) {
        this.categoryList = res.result
      }
    })
    const p2 = this.$axios.get('/home/lead_time').then(res => {
      if (res.code === 1) {
        this.leadTime = res.result.sort((a, b) => a.rank - b.rank)
      }
    })
    const p3 = this.$axios
      .post('/common/goodsFilterList', { pid: 2 })
      .then(res => {
        if (res.code === 1) {
          this.budget = res.result
        }
      })
    const p4 = this.$axios
      .post('/home/articleLists', { state: 'au', keyword: 'Indent Ideas' })
      .then(res => {
        this.indentIdeasArticle = res.result.data[0] || {}
      })
    const p5 = this.$axios
      .post('/home/articleLists', { state: 'au', keyword: 'Compliance' })
      .then(res => {
        this.complianceArticle = res.result.data[0] || {}
      })
    // 并联异步操作，总耗时===耗时最长的单个请求。之前的逻辑是串联，总耗时为所有请求耗时的累加
    await Promise.all([p1, p2, p3, p4, p5])
  },
  computed: {
    email() {
      return this.$store.state.userInfo?.email
    },
    compareLists() {
      return this.$store.state.compareList
    },
    productsList() {
      if (this.result?.goods) {
        return this.result.goods.map(item => {
          const temp = {
            ...item,
          }
          if (item.img) {
            temp.image = !this.$mediaRegExp.test(item.img)
              ? this.$OSS_PREFIX + item.img
              : item.img
          }
          return temp
        })
      } else {
        return []
      }
    },
  },
  watch: {
    keyword: {
      handler(newValue, oldValue) {
        // if (newValue.length > 2 && this.loaded) {
        if (newValue.length > 2) {
          this.debounce(this.search, 700)
        } else {
          setTimeout(() => {
            this.showType = 'none'
          }, 501)
        }
      },
      deep: true,
    },
    compareLists: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.compareList = cloneDeep(newValue)
        }
      },
      deep: true,
      immediate: true,
    },
    $route(to, from) {
      if (to.path == from.path && to.query.keyword != from.query.keyword) {
        location.reload()
      }
      if (!to.query.keyword) {
        this.keyword = ''
      }
    },
  },
  created() {
    // this.$store.dispatch('getCompareList')
  },
  mounted() {
    // if (this.$route.query.keyword && this.$route.query.keyword.length) {
    //   this.keyword = this.$route.query.keyword
    // }
    // setTimeout(() => {
    //   this.loaded = true
    // }, 0)
    window.addEventListener('click', this.closeLeadtimeAndBudget)

    if (process.env.NODE_ENV === 'development') {
      const client = algoliasearch(
        '7KGEFE6I2Z',
        'e39e202ace0a2fa12ea61095e6ede35d'
      )
      this.index = client.initIndex('mysql_test')
    } else {
      const client = algoliasearch(
        '2340OWI595',
        '2d4c53cdcf2bab0c361e589c2c2272fa'
      )
      this.index = client.initIndex('product_au')
    }
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeLeadtimeAndBudget)
  },
  methods: {
    ...mapMutations([
      'openDialog',
      'clearUserInfo',
      'clearCompareList',
      'getCompareList',
    ]),

    debounce(fn, wait) {
      if (this.timer !== null) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(fn, wait)
    },
    search() {
      this.result = { cate: [], subCate: [], firstCate: [] }
      this.showType = 'flex'
      this.loading = true

      const key = this.keyword ? this.keyword.trim() : ''
      if (this.index) {
        this.index
          .search(key, {
            hitsPerPage: 1000,
            restrictSearchableAttributes: [
              'name',
              'keywords',
              'colour',
              'moq',
              'collection',
              'filter',
            ],
          })
          .then(({ nbHits, hits }) => {
            const temp = {
              goods: [],
              total: nbHits || hits.length || 0,
              cate: [],
              firstCate: [],
              subCate: [],
            }
            const t1 = []
            const t2 = []
            hits
              .filter(i => i.status === 1 || i.status === '1' || i.status)
              .forEach(item => {
                let colorImg = ''
                item.colour_imgs = JSON.parse(item.colour_imgs)
                item.colour_imgs.sort((a, b) => a.name.length - b.name.length)
                if (
                  Array.isArray(item.colour_imgs) &&
                  item.colour_imgs.length
                ) {
                  item.colour_imgs.forEach(colorItem => {
                    if (colorItem.name && colorItem.name.length) {
                      if (
                        new RegExp(colorItem.name.toLowerCase(), 'i').test(key)
                      ) {
                        console.log(
                          item.product_code,
                          colorItem.name,
                          'replace'
                        )
                        colorImg = colorItem.img
                      } else if (
                        /\s/.test(colorItem.name) &&
                        colorItem.name
                          .split(' ')
                          .filter(a => a.length > 0)
                          .some(b => new RegExp(b.toLowerCase(), 'i').test(key))
                      ) {
                        console.log(
                          item.product_code,
                          colorItem.name,
                          'advance replace'
                        )
                        colorImg = colorItem.img
                      }
                    }
                  })
                  item.colour_imgs.forEach(i => {
                    if (i.name && i.name.length) {
                      if (key.toLowerCase().includes(i.name.toLowerCase())) {
                        console.log(
                          item.product_code,
                          i.name,
                          'complete replace'
                        )
                        colorImg = i.img
                      }
                    }
                  })
                  console.log('---')
                }
                temp.goods.push({
                  ...item,
                  img: colorImg || item.image || item.img,
                  product_name: item.product_name || item.name,
                })
                if (!item.category_id) return
                const id = parseInt(item.category_id)
                const categoryResult = this.getCategoryFromTree(
                  id,
                  this.categoryList
                )
                switch (categoryResult.lev) {
                  case 1:
                    if (!t1.includes(id)) {
                      t1.push(id)
                      temp.firstCate.push(categoryResult)
                    }
                    break
                  case 2:
                  case 3:
                    if (!t2.includes(id)) {
                      t2.push(id)
                      temp.subCate.push(categoryResult)
                    }
                    // 当前商品在2 3 级, 搜索出来的分类里面应同时展示1级.
                    if (!t1.includes(categoryResult.root.id)) {
                      t1.push(categoryResult.root.id)
                      temp.firstCate.push(categoryResult.root)
                    }
                  // no default
                }
              })

            this.result = temp
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    focus() {
      clearTimeout(this.timer)
      if (this.keyword) {
        this.showType = 'flex'
      }
    },
    blur() {
      this.timer = setTimeout(() => {
        this.showType = 'none'
      }, 200)
    },
    goSearchResultPage() {
      // 匹配同组件的路由更改query时, 页面不会被刷新
      // this.$router.push({
      //   name: 'category-searchResult',
      //   query: { keyword: this.keyword },
      // })
      location.href =
        location.origin + `/category/searchResult?keyword=${encodeURIComponent(this.keyword)}`
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/')
      })
    },
    compareAll() {
      if (this.compareList.length) {
        this.$router.push('/home/compareList')
      }
    },
    clearCompare(id) {
      this.$axios
        .post('home/compare_clear', {
          id,
          compare_keys: 'keys',
        })
        .then(res => {
          if (res.code === 1) {
            this.compareList.splice(
              this.compareList.findIndex(item => item.id === id),
              1
            )
          }
        })
    },
    clearAll() {
      if (this.compareList.length) {
        this.$axios
          .post('home/compare_clear', {
            id: 0,
            compare_keys: 'keys',
          })
          .then(res => {
            if (res.code === 1) {
              this.clearCompareList()
            }
          })
      }
    },
    openShopProduct() {
      this.isShopBlock = !this.isShopBlock
      this.isLeadTimeBlock = false
      this.isBudgetBlock = false
    },
    openLeadTime() {
      this.isLeadTimeBlock = !this.isLeadTimeBlock
      this.isShopBlock = false
      this.isBudgetBlock = false
    },
    openBudget() {
      this.isBudgetBlock = !this.isBudgetBlock
      this.isLeadTimeBlock = false
      this.isShopBlock = false
    },
    closeAllBlock() {
      this.isShopBlock = false
      this.isLeadTimeBlock = false
      this.isBudgetBlock = false
    },
    closeLeadtimeAndBudget(e) {
      const className = e.target?.className
      if (!['shopProducts', 'LeadTime', 'Budget'].includes(className)) {
        this.isShopBlock = false
        this.isLeadTimeBlock = false
        this.isBudgetBlock = false
      }
    },
    closePopoverAndNavigate() {
      this.$refs.userPopover.doClose();
    },
    // 从分类树数据里面找到对应分类
    getCategoryFromTree(id, tree) {
      let result = {}
      if (!tree.length) return result
      tree.forEach(i => {
        if (i.id === id) {
          result = cloneDeep(i)
          result.lev = 1
        }

        if (!Array.isArray(i.child)) return
        i.child.forEach(secondCate => {
          if (secondCate.id === id) {
            result = cloneDeep(secondCate)
            result.parentName = i.name
            result.lev = 2
            result.root = {
              name: i.name,
              id: i.id,
              pid: 0,
            }
          }

          if (!Array.isArray(secondCate.child)) return
          secondCate.child.forEach(thirdCate => {
            if (thirdCate.id === id) {
              result = cloneDeep(thirdCate)
              result.parentName = secondCate.name
              result.grandName = i.name
              result.lev = 3
              result.root = {
                name: i.name,
                id: i.id,
                pid: 0,
              }
            }
          })
        })
      })
      return result
    },
  },
}
