import { render, staticRenderFns } from "./product_builder_layout.vue?vue&type=template&id=6c4e98e6&scoped=true&"
var script = {}
import style0 from "./product_builder_layout.vue?vue&type=style&index=0&id=6c4e98e6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c4e98e6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PcHeaderLite: require('/Users/wu67/Projects/Au_front/components/PcHeaderLite.vue').default})
