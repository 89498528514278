export default function(to, from, savedPosition) {
      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      let position = false;

      // 自定义行为
      const reg = /\/category/;
      if (reg.test(to.path) && reg.test(from.path) || to.path !== from.path) {
        // 在5种分类页间跳转 或者 同组件路由跳转时, 重置滚动距离
        position = { x: 0, y: 0 };
      } else if (savedPosition) {
        position = savedPosition;
      }
      if (to.hash) {
        let hash = to.hash;
        // CSS.escape() is not supported with IE and Edge.
        if (
        typeof window.CSS !== 'undefined' &&
        typeof window.CSS.escape !== 'undefined')
        {
          hash = '#' + window.CSS.escape(hash.substr(1));
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash };
          } else {
            // 部分情况下, 点击带hash的路由链接跳转会没法正确识别到hash, 需要用这种方式处理.
            return new Promise((resolve) => {
              window.$nuxt.$once('triggerScroll', () => {
                position = { selector: hash };
                resolve(position);
              });
            });
          }
        } catch (e) {
          console.warn(
          'Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).');
        }
        return position;
      } else {
        return position;
      }
    }
