
import getCookie from '~/utils/getCookie'
import { Message } from "element-ui";

export default function ({store,route, req, res, redirect}) {
  let isClient = process.client;
  let isServer = process.server;
  let redirectURL = '/';
  var token;
  // var open_platform;

  // 在服务端
  if (isServer) {
      // 获取服务端cookie
    let cookies = getCookie.getcookiesInServer(req)
    // 获取当前服务端cookie中是否含有token字段
    token = cookies.token ? cookies.token : ''
    // open_platform = cookies.open_platform ? cookies.open_platform : ''
  }
  // 在客户端
  if (isClient) {
      // 获取客户端（本地）cookie中的token字段
    token = getCookie.getcookiesInClient('token')
    // open_platform = getCookie.getcookiesInClient('open_platform')
  }

  // 判断是否获取到token
  // 未获取到，重定向到home頁
  if (!token) {
    Message({
        message: 'Please login first!',
        type: 'error',
        duration: 3 * 1000
      });
    redirect(redirectURL)
  }
}