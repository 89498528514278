import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3e8e0b70 = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _608065c5 = () => interopDefault(import('../pages/media.vue' /* webpackChunkName: "pages/media" */))
const _67661193 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _1d03ad88 = () => interopDefault(import('../pages/setNewPassword.vue' /* webpackChunkName: "pages/setNewPassword" */))
const _70b08d0c = () => interopDefault(import('../pages/category/searchResult/index.vue' /* webpackChunkName: "pages/category/searchResult/index" */))
const _22a2ac99 = () => interopDefault(import('../pages/home/api.vue' /* webpackChunkName: "pages/home/api" */))
const _347893b0 = () => interopDefault(import('../pages/home/catalogue.vue' /* webpackChunkName: "pages/home/catalogue" */))
const _7fa3d3bc = () => interopDefault(import('../pages/home/compareList.vue' /* webpackChunkName: "pages/home/compareList" */))
const _e0ddcfc2 = () => interopDefault(import('../pages/home/contact.vue' /* webpackChunkName: "pages/home/contact" */))
const _6d94b49e = () => interopDefault(import('../pages/home/contactPromoCollection.vue' /* webpackChunkName: "pages/home/contactPromoCollection" */))
const _754408fb = () => interopDefault(import('../pages/home/currentFlyer/index.vue' /* webpackChunkName: "pages/home/currentFlyer/index" */))
const _5f1c83ce = () => interopDefault(import('../pages/home/downloadCenter.vue' /* webpackChunkName: "pages/home/downloadCenter" */))
const _e0be62d4 = () => interopDefault(import('../pages/home/EDMS.vue' /* webpackChunkName: "pages/home/EDMS" */))
const _61659c4e = () => interopDefault(import('../pages/home/FAQs.vue' /* webpackChunkName: "pages/home/FAQs" */))
const _5bc5b0a2 = () => interopDefault(import('../pages/home/loyaltyProgram.vue' /* webpackChunkName: "pages/home/loyaltyProgram" */))
const _2bd6a4b1 = () => interopDefault(import('../pages/home/myDetail/index.vue' /* webpackChunkName: "pages/home/myDetail/index" */))
const _dc871fac = () => interopDefault(import('../pages/home/newsLetter.vue' /* webpackChunkName: "pages/home/newsLetter" */))
const _767fe6ce = () => interopDefault(import('../pages/home/privacyPolicy.vue' /* webpackChunkName: "pages/home/privacyPolicy" */))
const _5212d98b = () => interopDefault(import('../pages/home/projects.vue' /* webpackChunkName: "pages/home/projects" */))
const _06e5d02c = () => interopDefault(import('../pages/home/refundReturns.vue' /* webpackChunkName: "pages/home/refundReturns" */))
const _4ae5251e = () => interopDefault(import('../pages/home/termsConditions.vue' /* webpackChunkName: "pages/home/termsConditions" */))
const _a810de8c = () => interopDefault(import('../pages/home/video.vue' /* webpackChunkName: "pages/home/video" */))
const _bf8db25a = () => interopDefault(import('../pages/home/myDetail/enquiryDetail.vue' /* webpackChunkName: "pages/home/myDetail/enquiryDetail" */))
const _4d39c55e = () => interopDefault(import('../pages/home/myDetail/orderDetail.vue' /* webpackChunkName: "pages/home/myDetail/orderDetail" */))
const _0412eb0a = () => interopDefault(import('../pages/home/myDetail/components/orderDetailPart.vue' /* webpackChunkName: "pages/home/myDetail/components/orderDetailPart" */))
const _900e64ea = () => interopDefault(import('../pages/3D/_id.vue' /* webpackChunkName: "pages/3D/_id" */))
const _a82dcebe = () => interopDefault(import('../pages/article/_type.vue' /* webpackChunkName: "pages/article/_type" */))
const _60e31f38 = () => interopDefault(import('../pages/category/_firstCategory/index.vue' /* webpackChunkName: "pages/category/_firstCategory/index" */))
const _d07daf50 = () => interopDefault(import('../pages/design/_id.vue' /* webpackChunkName: "pages/design/_id" */))
const _61ed7410 = () => interopDefault(import('../pages/news/_newsName.vue' /* webpackChunkName: "pages/news/_newsName" */))
const _b4a98b84 = () => interopDefault(import('../pages/pdf/_id.vue' /* webpackChunkName: "pages/pdf/_id" */))
const _3906184a = () => interopDefault(import('../pages/product-builder/_code.vue' /* webpackChunkName: "pages/product-builder/_code" */))
const _53a837db = () => interopDefault(import('../pages/product-record/_code.vue' /* webpackChunkName: "pages/product-record/_code" */))
const _6930c28d = () => interopDefault(import('../pages/product/_code.vue' /* webpackChunkName: "pages/product/_code" */))
const _1ba95a28 = () => interopDefault(import('../pages/qrcode/_id.vue' /* webpackChunkName: "pages/qrcode/_id" */))
const _4b715030 = () => interopDefault(import('../pages/render/_id.vue' /* webpackChunkName: "pages/render/_id" */))
const _e90a10f4 = () => interopDefault(import('../pages/category/_firstCategory/_secondCategory/index.vue' /* webpackChunkName: "pages/category/_firstCategory/_secondCategory/index" */))
const _504f6167 = () => interopDefault(import('../pages/orderShare/_aid/_id.vue' /* webpackChunkName: "pages/orderShare/_aid/_id" */))
const _8685aef6 = () => interopDefault(import('../pages/category/_firstCategory/_secondCategory/_thirdCategory/index.vue' /* webpackChunkName: "pages/category/_firstCategory/_secondCategory/_thirdCategory/index" */))
const _74358037 = () => interopDefault(import('../pages/orderShare/_uid/_aid/_id.vue' /* webpackChunkName: "pages/orderShare/_uid/_aid/_id" */))
const _9e6c109a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/category",
    component: _3e8e0b70,
    name: "category"
  }, {
    path: "/media",
    component: _608065c5,
    name: "media"
  }, {
    path: "/product",
    component: _67661193,
    name: "product"
  }, {
    path: "/setNewPassword",
    component: _1d03ad88,
    name: "setNewPassword"
  }, {
    path: "/category/searchResult",
    component: _70b08d0c,
    name: "category-searchResult"
  }, {
    path: "/home/api",
    component: _22a2ac99,
    name: "home-api"
  }, {
    path: "/home/catalogue",
    component: _347893b0,
    name: "home-catalogue"
  }, {
    path: "/home/compareList",
    component: _7fa3d3bc,
    name: "home-compareList"
  }, {
    path: "/home/contact",
    component: _e0ddcfc2,
    name: "home-contact"
  }, {
    path: "/home/contactPromoCollection",
    component: _6d94b49e,
    name: "home-contactPromoCollection"
  }, {
    path: "/home/currentFlyer",
    component: _754408fb,
    name: "home-currentFlyer"
  }, {
    path: "/home/downloadCenter",
    component: _5f1c83ce,
    name: "home-downloadCenter"
  }, {
    path: "/home/EDMS",
    component: _e0be62d4,
    name: "home-EDMS"
  }, {
    path: "/home/FAQs",
    component: _61659c4e,
    name: "home-FAQs"
  }, {
    path: "/home/loyaltyProgram",
    component: _5bc5b0a2,
    name: "home-loyaltyProgram"
  }, {
    path: "/home/myDetail",
    component: _2bd6a4b1,
    name: "home-myDetail"
  }, {
    path: "/home/newsLetter",
    component: _dc871fac,
    name: "home-newsLetter"
  }, {
    path: "/home/privacyPolicy",
    component: _767fe6ce,
    name: "home-privacyPolicy"
  }, {
    path: "/home/projects",
    component: _5212d98b,
    name: "home-projects"
  }, {
    path: "/home/refundReturns",
    component: _06e5d02c,
    name: "home-refundReturns"
  }, {
    path: "/home/termsConditions",
    component: _4ae5251e,
    name: "home-termsConditions"
  }, {
    path: "/home/video",
    component: _a810de8c,
    name: "home-video"
  }, {
    path: "/home/myDetail/enquiryDetail",
    component: _bf8db25a,
    name: "home-myDetail-enquiryDetail"
  }, {
    path: "/home/myDetail/orderDetail",
    component: _4d39c55e,
    name: "home-myDetail-orderDetail"
  }, {
    path: "/home/myDetail/components/orderDetailPart",
    component: _0412eb0a,
    name: "home-myDetail-components-orderDetailPart"
  }, {
    path: "/3D/:id?",
    component: _900e64ea,
    name: "3D-id"
  }, {
    path: "/article/:type?",
    component: _a82dcebe,
    name: "article-type"
  }, {
    path: "/category/:firstCategory",
    component: _60e31f38,
    name: "category-firstCategory"
  }, {
    path: "/design/:id?",
    component: _d07daf50,
    name: "design-id"
  }, {
    path: "/news/:newsName?",
    component: _61ed7410,
    name: "news-newsName"
  }, {
    path: "/pdf/:id?",
    component: _b4a98b84,
    name: "pdf-id"
  }, {
    path: "/product-builder/:code",
    component: _3906184a,
    name: "product-builder-code"
  }, {
    path: "/product-record/:code",
    component: _53a837db,
    name: "product-record-code"
  }, {
    path: "/product/:code",
    component: _6930c28d,
    name: "product-code"
  }, {
    path: "/qrcode/:id?",
    component: _1ba95a28,
    name: "qrcode-id"
  }, {
    path: "/render/:id?",
    component: _4b715030,
    name: "render-id"
  }, {
    path: "/category/:firstCategory/:secondCategory",
    component: _e90a10f4,
    name: "category-firstCategory-secondCategory"
  }, {
    path: "/orderShare/:aid?/:id?",
    component: _504f6167,
    name: "orderShare-aid-id"
  }, {
    path: "/category/:firstCategory/:secondCategory/:thirdCategory",
    component: _8685aef6,
    name: "category-firstCategory-secondCategory-thirdCategory"
  }, {
    path: "/orderShare/:uid?/:aid?/:id?",
    component: _74358037,
    name: "orderShare-uid-aid-id"
  }, {
    path: "/",
    component: _9e6c109a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
