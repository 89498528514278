
import { mapMutations } from 'vuex'
import dialogXXSuccess from './DIalogXXSuccess.vue'

export default {
  components: { 'dialog-XX-success': dialogXXSuccess },

  data() {
    return {
      footerBannerList: [],
      productList: [],
      indexConfigWebsite: [],
      aboutUsArticle: {},
      flag_scroll: false,
      scroll: 0,
      xxContentVisible: false,
      xxDesc: '',
    }
  },
  async fetch() {
    await this.$axios
      .post('home/indexBanner', { site: 1, type: 6 })
      .then(res => {
        this.footerBannerList = res.result.data
        // this.$store.commit("config/setConfigInfo", res.result);
      })
    await this.$axios.post('home/indexConfigWebsite', { site: 1 }).then(res => {
      this.indexConfigWebsite = res.result
      this.$store.commit('config/setConfigInfo', res.result)
    })
    await this.$axios.post('common/shopProductList').then(res => {
      this.productList = res.result
    })
    await this.$axios
      .post('/home/articleLists', { state: 'au', keyword: 'About us' })
      .then(res => {
        this.aboutUsArticle = res.result.data[0]
      })
  },
  computed: {
    audit_status() {
      return this.$store.state.userInfo?.audit_status === 1
    },
    isLogin() {
      return !!this.$store.state.userInfo?.email
    },
  },
  methods: {
    ...mapMutations('config', ['setConfigInfo']),
    ...mapMutations(['openDialog']),

    backTop() {
      var timer = setInterval(function () {
        document.documentElement.scrollTop -= 20
        if (document.documentElement.scrollTop <= 0) {
          clearInterval(timer)
        }
      }, 10)
    },
    handleScroll() {
      const scrollobj =
        document.body.scrollTop + document.documentElement.scrollTop
      if (scrollobj > 500) {
        this.flag_scroll = true
      } else {
        this.flag_scroll = false
      }
    },
    openLoginDialog() {
      if(this.$utils.checkLogin()){
        this.$router.push({name: 'news-newsName',params: { newsName: 'About-Us' }})
      }else{
        this.openDialog();
      }
    },
    goApiWord() {
      if (this.isLogin) {
        if (this.$store.state.userInfo.open_platform) {
          const { href } = this.$router.resolve({
            name: 'home-api',
          })
          window.open(href, '_black')
        } else {
          this.submitLoading = false
          this.xxContentVisible = true
          this.xxDesc = 'If you encounter difficulties, please contact info@promocollection.com.au'
          // this.$message.error(
          //   'If you encounter difficulties, please contact info@promocollection.com.au'
          // )
        }
      } else {
        this.openDialog()
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop)
  },
}
