import Vue from 'vue'
import dayjs from 'dayjs'
import { Message } from 'element-ui';

const utils = {
  // 检查是否登录
  checkLogin() {
    if (this.getCookie('token')) {
      return true;
    } else {
      return false;
    }
  },

  getCookie(cname) {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return "";
  },

  checkType(val) {
    return Object.prototype.toString.call(val).slice(8, -1)
  },

  // 时间格式化. 格式参考 https://day.js.org/docs/zh-CN/display/format
  formatTime(date, format = 'DD-MM-YYYY HH:mm:ss', isUnix = false) {
    let str = ''
    if (date) {
      str = isUnix ? dayjs.unix(date).format(format) : dayjs(date).format(format)
    }
    return str
  },

  /**
     * @deprecated 处理 pdf url，使其不在浏览器打开
     * @param {string} url
     */
  downloadBlob(url, filename) {
    fetch(url, {
      method: 'get',
      responseType: 'arraybuffer',
    })
      .then(function (res) {
        if (res.status !== 200) {
          return res.json()
        }
        return res.arrayBuffer()
      })
      .then((blobRes) => {
        // 生成 Blob 对象，设置 type 等信息
        const e = new Blob([blobRes], {
          type: 'application/octet-stream',
          'Content-Disposition': 'attachment'
        })
        // 将 Blob 对象转为 url
        const link = window.URL.createObjectURL(e)
        this.handleFileDownload(link, filename)
      }).catch(err => {
        console.error(err)
      })
  },

  downloadXhr(url, filename) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';
    let messageInstance = Message({
      message: 'Downloading...',
      duration: 0,
      type: 'info'
    });
    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        messageInstance.message = `Downloading...${percentComplete}%`;
      }
    };

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const blob = new Blob([xhr.response], { type: 'application/octet-stream' });
        const link = window.URL.createObjectURL(blob);
        this.handleFileDownload(link, filename);
        setTimeout(() => {
          Message.closeAll();
        }, 1000)
      } else {
        Message.error('Download failed');
      }
    };

    xhr.onerror = () => {
      Message.closeAll();
      Message.error('Download failed');
      // 不知为何，传参了个空的a标签，浏览器可下载形式下载了跨域 https，而不是打开文件
      // const link = document.createElement('a');
      // this.handleFileDownload(link, filename);
    };

    xhr.send();
  },

  handleFileDownload(url, filename, download = true) {
    const a = document.createElement('a');
    a.href = url;
    a.download = download && filename;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },

  isEmail(s) {
    return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
  },

  isMobile(s) {
    return /^1[0-9]{10}$/.test(s)
  },

  // 亚马逊图像尺寸缩略
  generateResizedImageUrl(urlString, width) {
    if (urlString) {
      const extractedString = urlString.replace(/^https?:\/\/[^/]+/, '');
      const resizedImageUrl = `${Vue.prototype.$OSS_PREFIX}/fit-in/${width}x0${extractedString}`;
      return resizedImageUrl;
    }
  },

  // 链接替换
  repaceDomain(urlString) {
    if (urlString) {
      const extractedString = urlString.replace(/^https?:\/\/[^/]+/, '');
      const resizedImageUrl = `${Vue.prototype.$OSS_S3_PREFIX}${extractedString}`;
      return resizedImageUrl;
    }
  }

}


Vue.prototype.$utils = utils;